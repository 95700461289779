// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import request from './request'

// 商品管理/审核信息-列表查询
export const getGoodsInfoList = (data: object) =>
  request('/payAdminServer/product/getProductList', null, 'POST_JSON', data)

// 商品管理/商品配置-新增商品页-选取自动续费型商品后，查询订阅组
export const getSubscriptionGroupList = (data: object) =>
  request('/payAdminServer/app/subscriptionGroup/list', null, 'POST_JSON', data)

// 商品管理/商品配置-新增商品页-获取默认价格国家下拉列表
export const getDefaultPriceCountryList = (data: object) =>
  request('/payAdminServer/product/getAppInfoByDbtIdAndProdChnl', null, 'POST_JSON', data)

// 商品管理/商品配置-新增商品页-某国阶梯价格获取
export const getLadderPrice = (data: object) =>
  request('/payAdminServer/product/getNativePoints', null, 'POST_JSON', data)
// 商品管理/商品配置-新增商品页-选取自动续费型商品后，创建订阅组
export const createSubscriptionGroup = (data: object) =>
  request('/payAdminServer/app/subscriptionGroup/add', null, 'POST_JSON', data)

// 商品管理/审核信息-上传审核截屏
export const uploadAuditScreenshot = (data: object) => request('/payAdminServer/file/upload', null, 'POST_FILE', data)

// 商品管理/审核信息-提交截屏信息
export const submitAuditScreenshot = (data: object) =>
  request('/payAdminServer/product/submit/screenshot', null, 'POST_JSON', data)

// 商品管理/审核信息-备注翻译
export const translateRemark = (data: object) =>
  request('/payAdminServer/product/remark/translator', null, 'POST_JSON', data)

// 商品管理/商品审核-提交备注
export const submitRemark = (data: object) =>
  request('/payAdminServer/product/submit/audit_remark', null, 'POST_JSON', data)

// 商品管理/商品审核-提交推广图片
export const submitPromotionImage = (data: object) =>
  request('/payAdminServer/product/submit/promotion', null, 'POST_JSON', data)

// 获取产品下拉框
export const queryGoodsOption = (data: object) =>
  request('/payAdminServer/app/dimension/condition', null, 'POST_JSON', data)
